import React from "react";
import { Avatar } from "@mui/material";
import styles from "./OurWork.module.css";
import images from "../../data/images";
import NavHeaderComponent from "../../components/NavHeaderComponent/NavHeaderComponent";

const OurWork = () => {
  const headerComponentView = (
    <div className={styles.headerContainer}>
      <img
        src={images.app_logo}
        className={styles.appLogoImage}
        alt={"app_logo"}
      />
      <div className={styles.listItemContainer}>
        <NavHeaderComponent screenName={"OurWork"} />
      </div>
    </div>
  );

  return (
    <div className={styles.homeContainer}>
      <div className={styles.iconWrap}>
        <img
          src={images.our_work_bg}
          className={styles.bgOurWorkStyle}
          alt={"bg_image_our_work"}
        />
        <img
          src={images.transparent_blue}
          className={styles.transparntViewStyle}
          alt={`transparent_blue`}
        />
      </div>
      <div className={styles.headerMainContainer}>{headerComponentView}</div>
      <div className={styles.ourWorkTitleView}>
        <text className={styles.ourTxtStyle}>
          Our <text className={styles.workTxtStyle}> Work</text>
        </text>
      </div>
      {/* Our work view */}
      <div className={styles.ourWorkContainer}>
        <img
          src={images.quote_icon}
          className={styles.quoteIconStyle}
          alt={`quote_icon`}
        />
        <text className={styles.makeEffortTxtStyle}>
          We make every effort to turn your idea into reality that changes user
          perspective.{" "}
          <text className={styles.haveAlookTxtStyle}>
            {" "}
            Have a look at our Work!
          </text>
        </text>
        <div className={styles.cardContainer}>
          <div className={styles.rowContainer}>
            <div className={styles.subcardContainer}>
              <img
                src={images.card_1}
                className={styles.cardIconStyle}
                alt={`card_1`}
              />
              <text className={styles.cardTitleTxtStyle}>One Co.</text>
              <text className={styles.cardDesTxtStyle}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </text>
            </div>
            <div className={styles.subcardContainer}>
              <img
                src={images.card_2}
                className={styles.cardIconStyle}
                alt={`card_2`}
              />
              <text className={styles.cardTitleTxtStyle}>XECTRA</text>
              <text className={styles.cardDesTxtStyle}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </text>
            </div>
          </div>
          <div className={styles.rowContainer2}>
            <div className={styles.subcardContainer}>
              <img
                src={images.card_3}
                className={styles.cardIconStyle}
                alt={`card_3`}
              />
              <text className={styles.cardTitleTxtStyle}>One Co.</text>
              <text className={styles.cardDesTxtStyle}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </text>
            </div>
            <div className={styles.subcardContainer}>
              <img
                src={images.card_4}
                className={styles.cardIconStyle}
                alt={`card_4`}
              />
              <text className={styles.cardTitleTxtStyle}>XECTRA</text>
              <text className={styles.cardDesTxtStyle}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </text>
            </div>
          </div>
        </div>
      </div>
      {/* Ready to build your brand up with us */}
      <div className={styles.readyToBuildContainer}>
        <text className={styles.readyToBuildTxt}>
          Ready to Build your{" "}
          <text className={styles.boldText}>Brand Up with Us!</text>
        </text>
        <button className={styles.contactUsBtn}>
          <text className={styles.contactUsText}>Contact Us Today!</text>
        </button>
      </div>
      {/* Bottom Company Info View */}
      <div className={styles.bottomCompanyInfoContainer}>
        <img
          src={images.app_logo}
          className={styles.appBottomLogoImage}
          alt={"app_logo"}
        />
        <div className={styles.missionTxtContainer}>
          <text className={styles.missionTxt}>
            Our mission is to be a trusted partner for businesses, helping them
            navigate the complex world of technology and achieve their strategic
            goals. We offer a comprehensive range of IT services.
          </text>
          <div>
            <img
              src={images.insta_logo}
              className={styles.socialIcons}
              alt={"insta_logo"}
            />
            <img
              src={images.twitter_logo}
              className={styles.socialIcons}
              alt={"twitter_logo"}
            />
            <img
              src={images.fb_logo}
              className={styles.socialIcons}
              alt={"fb_logo"}
            />
          </div>
        </div>
        <div className={styles.ourServicesContainer}>
          <text className={styles.ourServicesTxt}>Our Services</text>
          <text className={styles.ourServicesDes}>
            Software Development App Development Digital Marketing Graphic
            Designing
          </text>
        </div>
        <div className={styles.callUsContainer}>
          <text className={styles.callUsTitle}>Call Us</text>
          <div className={styles.infoDetailsContainer}>
            <div className={styles.iconAndDetailView}>
              <img
                src={images.location_Icon}
                className={styles.detailIcon}
                alt={"location_icon"}
              />
              <text className={styles.companyInfoTxt}>Ahmedabad, Gujarat, India</text>
            </div>
            <div className={styles.iconAndDetailView}>
              <img
                src={images.phone_Icon}
                className={styles.detailIcon}
                alt={"phone_icon"}
              />
              <text className={styles.companyInfoTxt}>+91 85111 08143</text>
            </div>
            <div className={styles.iconAndDetailView}>
              <img
                src={images.email_Icon}
                className={styles.detailIcon}
                alt={"email_icon"}
              />
              <text className={styles.companyInfoTxt}>
                Info@bitcodingsolution.com
              </text>
            </div>
          </div>
        </div>
      </div>
      <text className={styles.reservedTxt}>
        2024, BitCoding. All right reserved
      </text>
    </div>
  );
};

export default OurWork;
