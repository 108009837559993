import {useState} from 'react';
import _ from 'lodash';
import RootContext from './../../context/RootContext';

const RootContextWrapper = props => {
  const {children} = props;
  const [globalState, setGlobalState] = useState({});

  const setGlobal = (index, newData) => {
    const {[index]: oldData} = globalState;
    if (!_.isEqual(oldData, newData)) {
      setGlobalState({
        [index]: newData,
      });
    }
  };

  return (
    <RootContext.Provider
      value={{
        setGlobal: setGlobal,
      }}>
      {children}
    </RootContext.Provider>
  );
};

export default RootContextWrapper;

export const globalStateKeys = {
  isGlobalLoading: false,
};
