import React, {useState} from 'react';
import {Avatar} from '@mui/material';
import styles from '../NavHeaderComponent/NavHeader.module.css';
import images from '../../data/images';
import {useNavigate} from 'react-router-dom';

const NavHeaderComponent = props => {
  const [currentScreen, setCurrentScreen] = useState(props.screenName);
  const navigate = useNavigate();

  const onClickTab = path => {
    setCurrentScreen(JSON.parse(JSON.stringify(path)));
    switch (path) {
      case 'Home':
        navigate('/Home');
        break;
      case 'OurWork':
        navigate('/OurWork');
        break;
      case 'OurService':
        navigate('/OurService');
        break;
      case 'AboutUs':
        navigate('/AboutUs');
        break;
      case 'ContactUs':
        navigate('/ContactUs');
        break;
      default:
        navigate('/Home');
        break;
    }
  };

  return (
    <div>
      <ul className={styles.listContainer}>
        <li
          className={currentScreen === 'Home' ? styles.selectedLabelStyle : styles.labelItemStyle}
          onClick={() => onClickTab('Home')}>
          Home
        </li>
        <li
          className={
            currentScreen === 'OurWork' ? styles.selectedLabelStyle : styles.labelItemStyle
          }
          onClick={() => onClickTab('OurWork')}>
          Our work
        </li>
        <li
          className={
            currentScreen === 'OurService' ? styles.selectedLabelStyle : styles.labelItemStyle
          }
          onClick={() => onClickTab('OurService')}>
          Our Service
        </li>
        <li
          className={
            currentScreen === 'AboutUs' ? styles.selectedLabelStyle : styles.labelItemStyle
          }
          onClick={() => onClickTab('AboutUs')}>
          About Us
        </li>
        <li
          className={
            currentScreen === 'ContactUs' ? styles.selectedLabelStyle : styles.labelItemStyle
          }
          onClick={() => onClickTab('ContactUs')}>
          Contact Us
        </li>
      </ul>
    </div>
  );
};

export default NavHeaderComponent;
