import MainRoutes from '../../routes/MainRoutes';
import RootContextWrapper from '../../components/RootContextWrapper/RootContextWrapper';

const App = props => {
  return (
    <RootContextWrapper>
      {/* <Modal /> */}
      <div className="appContainer">{MainRoutes(props)}</div>
      {/* <PageLoader /> */}
    </RootContextWrapper>
  );
};

export default App;
