import React from 'react';
import {render} from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './containers/App/App';
import './index.css';
import './mui-dialog.css';
import './mui-calendar.css';
import './meyer.css';
import './fonts.css';

const MOUNT_NODE = document.getElementById('root');

const renderApp = RootComponent => {
  render(
    <BrowserRouter basename={'/'}>
      <RootComponent />
    </BrowserRouter>,
    MOUNT_NODE,
  );
};

renderApp(App);
