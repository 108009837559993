import React from "react";
import styles from "../OurService/OurService.module.css";
import images from "../../data/images";
import NavHeaderComponent from "../../components/NavHeaderComponent/NavHeaderComponent";

const OurService = () => {
  const headerComponentView = (
    <div className={styles.headerContainer}>
      <img src={images.app_logo} className={styles.appLogoImage} />
      <div className={styles.listItemContainer}>
        <NavHeaderComponent screenName={"OurService"} />
      </div>
    </div>
  );

  return (
    <div className={styles.homeContainer}>
      <div className={styles.iconWrap}>
        <img
          src={images.our_services_bg}
          className={styles.bgOurWorkStyle}
          alt={"bg_image_our_work"}
        />
        <img
          src={images.transparent_blue}
          className={styles.transparntViewStyle}
          alt={`transparent_blue`}
        />
      </div>
      <div className={styles.headerMainContainer}>{headerComponentView}</div>
      <div className={styles.ourWorkTitleView}>
        <text className={styles.ourTxtStyle}>
          Our <text className={styles.workTxtStyle}> Services</text>
        </text>
      </div>
      {/* Quate and our services view */}
      <div className={styles.ourWorkContainer}>
        <img
          src={images.quote_icon}
          className={styles.quoteIconStyle}
          alt={`quote_icon`}
        />
        <text className={styles.makeEffortTxtStyle}>
          We’re Navigating the complexities of Technology with Expert guidance
          with our IT Services!
        </text>
        <div className={styles.cardView}>
          <div className={styles.cardContentView}>
            <text className={styles.serviceTitleLeft}>Website Developer</text>
            <text className={styles.serviceDesLeft}>
              Whether you need a responsive e-commerce platform, a dynamic
              corporate website, or a captivating portfolio site, we deliver
              tailored solutions that leave a lasting impression.
            </text>
          </div>
          <img
            src={images.service_1}
            className={styles.serviceIconStyle}
            alt={"service_1"}
          />
        </div>
        <div className={styles.cardView}>
          <img
            src={images.service_2}
            className={styles.serviceIconStyle}
            alt={"service_2"}
          />
          <div className={styles.cardContentView}>
            <text className={styles.serviceTitleRight}>
              Andoid/IOS Developer
            </text>
            <text className={styles.serviceDesRight}>
              Turn your app ideas into reality with our expert Android
              development services. Our skilled team of developers specializes
              in creating high-performance, user-friendly Android applications
              that deliver exceptional experiences.
            </text>
          </div>
        </div>
        <div className={styles.cardView}>
          <div className={styles.cardContentView}>
            <text className={styles.serviceTitleLeft}>Digital Marketing</text>
            <text className={styles.serviceDesLeft}>
              Our team of digital marketing experts specializes in crafting
              data-backed campaigns that drive traffic, increase conversions,
              and boost your brand's visibility.
            </text>
          </div>
          <img
            src={images.service_3}
            className={styles.serviceIconStyle}
            alt={"service_3"}
          />
        </div>
        <div className={styles.cardView}>
          <img
            src={images.service_4}
            className={styles.serviceIconStyle}
            alt={"service_4"}
          />
          <div className={styles.cardContentView}>
            <text className={styles.serviceTitleRight}>Graphic Designing</text>
            <text className={styles.serviceDesRight}>
              Elevate your brand's visual identity with our professional graphic
              designing services. Our creative team of designers combines
              artistic flair with strategic thinking to create stunning visuals
              that communicate your brand's story effectively.
            </text>
          </div>
        </div>
      </div>
      {/* Ready to build your brand up with us */}
      <div className={styles.readyToBuildContainer}>
        <text className={styles.readyToBuildTxt}>
          Ready to Build your{" "}
          <text className={styles.boldText}>Brand Up with Us!</text>
        </text>
        <button className={styles.contactUsBtn}>
          <text className={styles.contactUsText}>Contact Us Today!</text>
        </button>
      </div>
      {/* Bottom Company Info View */}
      <div className={styles.bottomCompanyInfoContainer}>
        <img
          src={images.app_logo}
          className={styles.appBottomLogoImage}
          alt={"app_logo"}
        />
        <div className={styles.missionTxtContainer}>
          <text className={styles.missionTxt}>
            Our mission is to be a trusted partner for businesses, helping them
            navigate the complex world of technology and achieve their strategic
            goals. We offer a comprehensive range of IT services.
          </text>
          <div>
            <img
              src={images.insta_logo}
              className={styles.socialIcons}
              alt={"insta_logo"}
            />
            <img
              src={images.twitter_logo}
              className={styles.socialIcons}
              alt={"twitter_logo"}
            />
            <img
              src={images.fb_logo}
              className={styles.socialIcons}
              alt={"fb_logo"}
            />
          </div>
        </div>
        <div className={styles.ourServicesContainer}>
          <text className={styles.ourServicesTxt}>Our Services</text>
          <text className={styles.ourServicesDes}>
            Software Development App Development Digital Marketing Graphic
            Designing
          </text>
        </div>
        <div className={styles.callUsContainer}>
          <text className={styles.callUsTitle}>Call Us</text>
          <div className={styles.infoDetailsContainer}>
            <div className={styles.iconAndDetailView}>
              <img
                src={images.location_Icon}
                className={styles.detailIcon}
                alt={"location_icon"}
              />
              <text className={styles.companyInfoTxt}>Ahmedabad, Gujarat, India</text>
            </div>
            <div className={styles.iconAndDetailView}>
              <img
                src={images.phone_Icon}
                className={styles.detailIcon}
                alt={"phone_icon"}
              />
              <text className={styles.companyInfoTxt}>+91 85111 08143</text>
            </div>
            <div className={styles.iconAndDetailView}>
              <img
                src={images.email_Icon}
                className={styles.detailIcon}
                alt={"email_icon"}
              />
              <text className={styles.companyInfoTxt}>
                Info@bitcodingsolution.com
              </text>
            </div>
          </div>
        </div>
      </div>
      <text className={styles.reservedTxt}>
        2024, BitCoding. All right reserved
      </text>
    </div>
  );
};

export default OurService;
