import images from "../data/images";

export const workItems1 = [
  { id: 1, title: "Expert in Tools", image: images.work_1 },
  { id: 2, title: "Fast Solutions", image: images.work_2 },
  { id: 3, title: "Managment", image: images.work_3 },
  { id: 4, title: "24 Hour Availability", image: images.work_4 },
];

export const workItems2 = [
  { id: 5, title: "Strong Relationshiop", image: images.work_5 },
  { id: 6, title: "Happy Clients", image: images.work_6 },
  { id: 7, title: "Commitment", image: images.work_7 },
  { id: 8, title: "Experienced Team", image: images.work_8 },
];
