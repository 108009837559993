import React from "react";
import styles from "../Home/Home.module.css";
import images from "../../data/images";
import NavHeaderComponent from "../../components/NavHeaderComponent/NavHeaderComponent";
import OurExpectationItem from "../../components/OurExpectationItem/OurExpectationItem";
import { workItems1, workItems2 } from "../../constants";

const Home = () => {
  const headerComponentView = (
    <div className={styles.headerContainer}>
      <img
        src={images.app_logo}
        className={styles.appLogoImage}
        alt={"app_logo"}
      />
      <div className={styles.listItemContainer}>
        <NavHeaderComponent screenName={"Home"} />
      </div>
    </div>
  );

  const headerBrandComponent = (
    <div className={styles.headerTitleContainer}>
      <div className={styles.headerTitleText}>
        <text className={styles.brandLevelHeaderText}>
          Taking Your Brand to the Next Level!
        </text>
        <text className={styles.brandLevelSubText}>
          We are here to help your brand to reach to the next level!
        </text>
      </div>
      <img
        src={images.homeBrand_img}
        className={styles.brandLogoImgStyle}
        alt={"home_brand"}
      />
    </div>
  );

  const renderWorksAtBitCoding = (
    <div className={styles.workItemsContainer}>
      <div className={styles.work1Wrapper}>
        {workItems1.map((item) => {
          return (
            <div className={styles.singleWorkItemCard}>
              <img
                src={item.image}
                className={styles.workItemImageStyle}
                alt={`work_${item.id}`}
              />
              <img
                src={images.black_transperant}
                className={styles.transparntViewStyle}
                alt={`work_${item.id}`}
              />
              <text className={styles.txtWorkTitle}>{item.title}</text>
            </div>
          );
        })}
      </div>
      <div className={styles.work2Wrapper}>
        {workItems2.map((item) => {
          return (
            <div className={styles.singleWorkItemCard}>
              <img
                src={item.image}
                className={styles.workItemImageStyle}
                alt={`work_${item.id}`}
              />
              <img
                src={images.black_transperant}
                className={styles.transparntViewStyle}
                alt={`work_${item.id}`}
              />
              <text className={styles.txtWorkTitle}>{item.title}</text>
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <div className={styles.homeContainer}>
      <div className={styles.iconWrap}>
        <img
          src={images.sky_background}
          className={styles.bgImageSkyBlue}
          alt={"background_image"}
        />
      </div>
      {/* Header Component View */}
      <div className={styles.headerMainContainer}>
        {headerComponentView}
        {headerBrandComponent}
      </div>
      {/* Our Expertise View */}
      <div className={styles.expertiseContainer}>
        <text className={styles.expertiseTitleText}>
          <text className={styles.expertiseOurSubText}>Our</text>
          &nbsp;
          <text className={styles.expertiseNextSubText}>Expertise</text>
        </text>
        <div className={styles.expectationItemContainer}>
          <OurExpectationItem
            imgValue={images.software_development}
            expectationItemText="Software \n Development"
            id={1}
          />
          <OurExpectationItem
            imgValue={images.website_development}
            expectationItemText="Website \n Development"
            id={2}
          />
          <OurExpectationItem
            imgValue={images.mobile_development}
            expectationItemText="Mobile \n Development"
            id={3}
          />
        </div>
        <div className={styles.expectationItemContainer2}>
          <OurExpectationItem
            imgValue={images.digital_marketing}
            expectationItemText="Digital Marketing"
            id={4}
          />
          <OurExpectationItem
            imgValue={images.graphic_desgning}
            expectationItemText="Graphic Designing"
            id={5}
          />
        </div>
      </div>
      {/* Complete Creative Solution View */}
      <div className={styles.creativeContainer}>
        <img
          src={images.girl_pic}
          className={styles.creativeModelPicStyle}
          alt={"model_pic"}
        />
        <div className={styles.creativeDetailView}>
          <text className={styles.creativeTitleTxt}>
            A Complete Creative Solutions Provider!
          </text>
          <text className={styles.creativeDesTxt}>
            At BitCoding Solutions, we are passionate about harnessing the power
            of technology to drive innovation and empower businesses. With a
            team of skilled professionals and a wealth of industry experience,
            we strive to provide cutting-edge IT solutions that meet the unique
            needs of our clients. Our mission is to be a trusted partner for
            businesses, helping them navigate the complex world of technology
            and achieve their strategic goals. We offer a comprehensive range of
            IT services.
          </text>
        </div>
      </div>
      {/* Why work with Bitcoding View */}
      <div className={styles.whyWorkWithUsContainer}>
        <text className={styles.txtWhyWorkTitle}>
          Why Work With&nbsp;<text className={styles.boldBitText}>Bit</text>
          Coding?
        </text>
        {renderWorksAtBitCoding}
      </div>
      {/* Ready to build your brand up with us */}
      <div className={styles.readyToBuildContainer}>
        <text className={styles.readyToBuildTxt}>
          Ready to Build your{" "}
          <text className={styles.boldText}>Brand Up with Us!</text>
        </text>
        <button className={styles.contactUsBtn}>
          <text className={styles.contactUsText}>Contact Us Today!</text>
        </button>
      </div>
      {/* Bottom Company Info View */}
      <div className={styles.bottomCompanyInfoContainer}>
        <img
          src={images.app_logo}
          className={styles.appBottomLogoImage}
          alt={"app_logo"}
        />
        <div className={styles.missionTxtContainer}>
          <text className={styles.missionTxt}>
            Our mission is to be a trusted partner for businesses, helping them
            navigate the complex world of technology and achieve their strategic
            goals. We offer a comprehensive range of IT services.
          </text>
          <div>
            <img
              src={images.insta_logo}
              className={styles.socialIcons}
              alt={"insta_logo"}
            />
            <img
              src={images.twitter_logo}
              className={styles.socialIcons}
              alt={"twitter_logo"}
            />
            <img
              src={images.fb_logo}
              className={styles.socialIcons}
              alt={"fb_logo"}
            />
          </div>
        </div>
        <div className={styles.ourServicesContainer}>
          <text className={styles.ourServicesTxt}>Our Services</text>
          <text className={styles.ourServicesDes}>
            Software Development App Development Digital Marketing Graphic
            Designing
          </text>
        </div>
        <div className={styles.callUsContainer}>
          <text className={styles.callUsTitle}>Call Us</text>
          <div className={styles.infoDetailsContainer}>
            <div className={styles.iconAndDetailView}>
              <img
                src={images.location_Icon}
                className={styles.detailIcon}
                alt={"location_icon"}
              />
              <text className={styles.companyInfoTxt}>Ahmedabad, Gujarat, India</text>
            </div>
            <div className={styles.iconAndDetailView}>
              <img
                src={images.phone_Icon}
                className={styles.detailIcon}
                alt={"phone_icon"}
              />
              <text className={styles.companyInfoTxt}>+91 85111 08143</text>
            </div>
            <div className={styles.iconAndDetailView}>
              <img
                src={images.email_Icon}
                className={styles.detailIcon}
                alt={"email_icon"}
              />
              <text className={styles.companyInfoTxt}>Info@bitcodingsolution.com</text>
            </div>
          </div>
        </div>
      </div>
      <text className={styles.reservedTxt}>2024, BitCoding. All right reserved</text>
    </div>
  );
};

export default Home;
