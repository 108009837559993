import React from "react";
import { Avatar } from "@mui/material";
import styles from "../AboutUs/AboutUs.module.css";
import images from "../../data/images";
import NavHeaderComponent from "../../components/NavHeaderComponent/NavHeaderComponent";

const strengths = [
  { text: "In-House Team" },
  { text: "Technical Expertise" },
  { text: "Marketing Experts" },
  { text: "Planning & Analyzing" },
];
const AboutUs = () => {
  const headerComponentView = (
    <div className={styles.headerContainer}>
      <img src={images.app_logo} className={styles.appLogoImage} />
      <div className={styles.listItemContainer}>
        <NavHeaderComponent screenName={"AboutUs"} />
      </div>
    </div>
  );

  return (
    <div className={styles.homeContainer}>
      <div className={styles.iconWrap}>
        <img
          src={images.about_us_bg}
          className={styles.bgOurWorkStyle}
          alt={"bg_image_about_us"}
        />
        <img
          src={images.transparent_blue}
          className={styles.transparntViewStyle}
          alt={`transparent_blue`}
        />
      </div>
      <div className={styles.headerMainContainer}>{headerComponentView}</div>
      <div className={styles.ourWorkTitleView}>
        <text className={styles.ourTxtStyle}>
          <text className={styles.workTxtStyle}> About </text> Us
        </text>
      </div>
      {/* Quote View */}
      <div className={styles.ourWorkContainer}>
        <img
          src={images.quote_icon}
          className={styles.quoteIconStyle}
          alt={`quote_icon`}
        />
        <text className={styles.makeEffortTxtStyle}>
          We're here to Understand your business objectives, and deliver
          solutions that align with your vision.
        </text>
        <div className={styles.cardView}>
          <img
            src={images.about_us_1}
            className={styles.aboutIconStyle}
            alt={"service_1"}
          />
          <div className={styles.cardContentView}>
            <text className={styles.serviceTitleRight}>Who We Are</text>
            <text className={styles.serviceDesRight}>
              Welcome to BitCoding IT Solutions! We are passionate about
              harnessing the power of technology to drive innovation and empower
              businesses. With a team of skilled professionals and a wealth of
              industry experience, we strive to provide cutting-edge IT
              solutions that meet the unique needs of our clients. Our mission
              is to be a trusted partner for businesses, helping them navigate
              the complex world of technology and achieve their strategic goals.
              We offer a comprehensive range of IT services.
            </text>
          </div>
        </div>
        <div className={styles.cardView}>
          <div className={styles.cardContentView}>
            <text className={styles.serviceTitleLeft}>What We Do</text>
            <text className={styles.serviceDesLeft}>
              At BitCoding Solutions, We prioritize customer satisfaction and
              strive for excellence in everything we do. We believe in building
              long-term relationships with our clients, understanding their
              business objectives, and delivering solutions that align with
              their vision. Contact us Today to discover how BitCoding Solutions
              can empower your business through technology!
            </text>
          </div>
          <img
            src={images.about_us_2}
            className={styles.aboutIconStyle}
            alt={"service_2"}
          />
        </div>

        {/* Strengths Section */}
        <div className={styles.strengthSection}>
          <h2 className={styles.strengthTitle}>Our Strength</h2>
          <div className={styles.strengthGrid}>
            {strengths.map((strength, index) => (
              <div key={index} className={styles.strengthItem}>
                <img
                  src={images.check}
                  className={styles.checkIconStyle}
                  alt={"check"}
                />
                <span className={styles.strengthText}>{strength.text}</span>
              </div>
            ))}
          </div>
        </div>

        {/* Workflow Section */}
        <div className={styles.workflowSection}>
          <h2 className={styles.workflowTitle}>Our Workflow</h2>
          <img
            src={images.workflow}
            className={styles.workFlowStyle}
            alt={"workflow"}
          />
        </div>
      </div>
      {/* Ready to build your brand up with us */}
      <div className={styles.readyToBuildContainer}>
        <text className={styles.readyToBuildTxt}>
          Ready to Build your{" "}
          <text className={styles.boldText}>Brand Up with Us!</text>
        </text>
        <button className={styles.contactUsBtn}>
          <text className={styles.contactUsText}>Contact Us Today!</text>
        </button>
      </div>
      {/* Bottom Company Info View */}
      <div className={styles.bottomCompanyInfoContainer}>
        <img
          src={images.app_logo}
          className={styles.appBottomLogoImage}
          alt={"app_logo"}
        />
        <div className={styles.missionTxtContainer}>
          <text className={styles.missionTxt}>
            Our mission is to be a trusted partner for businesses, helping them
            navigate the complex world of technology and achieve their strategic
            goals. We offer a comprehensive range of IT services.
          </text>
          <div>
            <img
              src={images.insta_logo}
              className={styles.socialIcons}
              alt={"insta_logo"}
            />
            <img
              src={images.twitter_logo}
              className={styles.socialIcons}
              alt={"twitter_logo"}
            />
            <img
              src={images.fb_logo}
              className={styles.socialIcons}
              alt={"fb_logo"}
            />
          </div>
        </div>
        <div className={styles.ourServicesContainer}>
          <text className={styles.ourServicesTxt}>Our Services</text>
          <text className={styles.ourServicesDes}>
            Software Development App Development Digital Marketing Graphic
            Designing
          </text>
        </div>
        <div className={styles.callUsContainer}>
          <text className={styles.callUsTitle}>Call Us</text>
          <div className={styles.infoDetailsContainer}>
            <div className={styles.iconAndDetailView}>
              <img
                src={images.location_Icon}
                className={styles.detailIcon}
                alt={"location_icon"}
              />
              <text className={styles.companyInfoTxt}>Ahmedabad, Gujarat, India</text>
            </div>
            <div className={styles.iconAndDetailView}>
              <img
                src={images.phone_Icon}
                className={styles.detailIcon}
                alt={"phone_icon"}
              />
              <text className={styles.companyInfoTxt}>+91 85111 08143</text>
            </div>
            <div className={styles.iconAndDetailView}>
              <img
                src={images.email_Icon}
                className={styles.detailIcon}
                alt={"email_icon"}
              />
              <text className={styles.companyInfoTxt}>
                Info@bitcodingsolution.com
              </text>
            </div>
          </div>
        </div>
      </div>
      <text className={styles.reservedTxt}>
        2024, BitCoding. All right reserved
      </text>
    </div>
  );
};

export default AboutUs;
