import sky_background from '../static/images/sky_background.svg';
import app_logo from '../static/images/app_logo.svg';
import homeBrand_img from '../static/images/homeBrand_img.png';
import digital_marketing from '../static/images/digital_marketing.png';
import graphic_desgning from '../static/images/graphic_designing.png';
import mobile_development from '../static/images/mobile_development.png';
import software_development from '../static/images/software_development.png';
import website_development from '../static/images/website_development.png';
import girl_pic from '../static/images/girl_pic.png';
import work_1 from '../static/images/work_1.png';
import work_2 from '../static/images/work_2.png';
import work_3 from '../static/images/work_3.png';
import work_4 from '../static/images/work_4.png';
import work_5 from '../static/images/work_5.png';
import work_6 from '../static/images/work_6.png';
import work_7 from '../static/images/work_7.png';
import work_8 from '../static/images/work_8.png';
import black_transperant from '../static/images/black_transperant.png';
import insta_logo from '../static/images/insta_logo.svg';
import fb_logo from '../static/images/fb_logo.svg';
import twitter_logo from '../static/images/twitter_logo.png';
import phone_Icon from '../static/images/phone_Icon.svg';
import location_Icon from '../static/images/location_Icon.svg';
import email_Icon from '../static/images/email_Icon.svg';
import our_work_bg from '../static/images/our_work_bg.svg';
import our_services_bg from '../static/images/our_services_bg.svg';
import about_us_bg from '../static/images/about_us_bg.svg';
import transparent_blue from '../static/images/transparent_blue.svg';
import quote_icon from '../static/images/quote_icon.svg';
import card_1 from '../static/images/card_1.svg';
import card_2 from '../static/images/card_2.svg';
import card_3 from '../static/images/card_3.svg';
import card_4 from '../static/images/card_4.svg';
import service_4 from '../static/images/service_1.svg';
import service_3 from '../static/images/service_2.svg';
import service_2 from '../static/images/service_3.svg';
import service_1 from '../static/images/service_4.svg';
import about_us_1 from '../static/images/about_us_1.svg';
import about_us_2 from '../static/images/about_us_2.svg';
import check from '../static/images/check.svg';
import workflow from '../static/images/workflow.svg';
import contact_us_bg from '../static/images/contact_us_bg.svg';
import call from '../static/images/call.svg';
import global from '../static/images/global.svg';
import email from '../static/images/email.svg';
import facebook from '../static/images/facebook.svg';
import twitter from '../static/images/twitter.png';
import linkdin from '../static/images/linkdin.svg';
import instagram from '../static/images/instagram.svg';

const images = {};
images.sky_background = sky_background;
images.app_logo = app_logo;
images.homeBrand_img = homeBrand_img;
images.digital_marketing = digital_marketing;
images.graphic_desgning = graphic_desgning;
images.mobile_development = mobile_development;
images.software_development = software_development;
images.website_development = website_development;
images.girl_pic = girl_pic;
images.work_1 = work_1;
images.work_2 = work_2;
images.work_3 = work_3;
images.work_4 = work_4;
images.work_5 = work_5;
images.work_6 = work_6;
images.work_7 = work_7;
images.work_8 = work_8;
images.black_transperant = black_transperant;
images.insta_logo = insta_logo;
images.fb_logo = fb_logo;
images.twitter_logo = twitter_logo;
images.email_Icon = email_Icon;
images.location_Icon = location_Icon;
images.phone_Icon = phone_Icon;
images.our_work_bg = our_work_bg;
images.transparent_blue = transparent_blue;
images.quote_icon = quote_icon;
images.card_1 = card_1;
images.card_2 = card_2;
images.card_3 = card_3;
images.card_4 = card_4;
images.our_services_bg = our_services_bg;
images.service_1 = service_1;
images.service_2 = service_2;
images.service_3 = service_3;
images.service_4 = service_4;
images.about_us_bg = about_us_bg;
images.about_us_1 = about_us_1;
images.about_us_2 = about_us_2;
images.check = check;
images.workflow = workflow;
images.contact_us_bg = contact_us_bg;
images.email = email;
images.call = call;
images.global = global;
images.facebook = facebook;
images.twitter = twitter;
images.linkdin = linkdin;
images.instagram = instagram;

export default images;
