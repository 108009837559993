import React, {useState} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import Home from '../containers/Home/Home';
import OurWork from '../containers/OurWork/OurWork';
import OurService from '../containers/OurService/OurService';
import AboutUs from '../containers/AboutUs/AboutUs';
import ContactUs from '../containers/ContactUs/ContactUs';

const MainRoutes = props => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/Home" replace={true} />} />
      <Route exact path={'/Home'} element={<Home {...props} />} />
      <Route path="/OurWork" element={<OurWork {...props} />} />
      <Route path="/OurService" element={<OurService {...props} />} />
      <Route path="/AboutUs" element={<AboutUs {...props} />} />
      <Route path="/ContactUs" element={<ContactUs {...props} />} />
    </Routes>
  );
};

export default MainRoutes;
