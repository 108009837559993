import React from "react";
import styles from "../OurExpectationItem/OurExpectationItem.module.css";

const OurExpectationItem = (props) => {
  return (
    <div className={styles.itemContainer}>
      <div className={styles.iconContainer}>
        <img
          className={props.id === 3 ? styles.mobileIconStyle : styles.iconStyle}
          src={props.imgValue}
        />
      </div>
      <text className={styles.txtTitleStyle}>
        {addLineBreak(props.expectationItemText)}
      </text>
      {/* <div className={styles.headerMainContainer}>{headerComponentView}</div> */}
    </div>
  );
};

const addLineBreak = (str) =>
  str.split("\\n").map((subStr) => {
    return (
      <>
        {subStr}
        <br />
      </>
    );
  });

export default OurExpectationItem;
