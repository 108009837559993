import React, { useState } from "react";
import styles from "../ContactUs/ContactUs.module.css";
import images from "../../data/images";
import NavHeaderComponent from "../../components/NavHeaderComponent/NavHeaderComponent";
import emailjs from "@emailjs/browser";

const ContactUs = () => {
  const headerComponentView = (
    <div className={styles.headerContainer}>
      <img src={images.app_logo} className={styles.appLogoImage} />
      <div className={styles.listItemContainer}>
        <NavHeaderComponent screenName={"ContactUs"} />
      </div>
    </div>
  );

  const ContactCard = () => {
    return (
      <div className={styles.card}>
        <div className={styles.contactInfo}>
          <div className={styles.contactItem}>
            <img src={images.email} alt="Email Icon" className={styles.icon} />
            <text className={styles.commonTxtStyle}>
              info@bitcodingsolution.com
            </text>
          </div>
          <div className={styles.contactItem}>
            <img src={images.call} alt="Phone Icon" className={styles.icon} />
            <text className={styles.commonTxtStyle}>+91 85111 08143</text>
          </div>
          <div className={styles.contactItem}>
            <img
              src={images.global}
              alt="Website Icon"
              className={styles.icon}
            />
            <text className={styles.commonTxtStyle}>
              www.bitcodingsolution.com
            </text>
          </div>
        </div>
        <div className={styles.socialIconsCard}>
          <img
            src={images.facebook}
            alt="Facebook Icon"
            className={styles.socialIcon}
          />
          <img
            src={images.twitter}
            alt="Twitter Icon"
            className={styles.socialIcon}
          />
          <img
            src={images.linkdin}
            alt="LinkedIn Icon"
            className={styles.socialIcon}
          />
          <img
            src={images.instagram}
            alt="Instagram Icon"
            className={styles.socialIcon}
          />
        </div>
      </div>
    );
  };

  const FormView = () => {
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      message: "",
      subject: "",
      phone: "",
    });

    const handleChange = (e) => {
      e.preventDefault();
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };

    const sendEmail = (e) => {
      e.preventDefault();

      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_KEY,
          process.env.REACT_APP_TEMPLATE_KEY,
          e.target,
          process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY
        )
        .then(
          (result) => {
            console.log("Email sent successfully:", result.text);
            alert("Thank you for reaching out! We will get back to you soon.");
          },
          (error) => {
            console.error("Error sending email:", error.text);
            alert("Failed to send your message. Please try again later.");
          }
        );

      setFormData({
        name: "",
        email: "",
        message: "",
        subject: "",
        phone: "",
      });
    };

    return (
      <form className={styles.formView} onSubmit={sendEmail}>
        <div className={styles.formSection}>
          <div className={styles.inputRow}>
            <div className={styles.inputGroup}>
              <input
                type="text"
                name="name"
                placeholder="Name"
                className={styles.inputStyle}
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputGroup}>
              <input
                type="email"
                name="email"
                placeholder="Email"
                className={styles.inputStyle}
                value={formData.email}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.inputRow}>
            <div className={styles.inputGroup}>
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                className={styles.inputStyle}
                value={formData.subject}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputGroup}>
              <input
                type="tel"
                name="phone"
                placeholder="Phone"
                className={styles.inputStyle}
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
          </div>
          <textarea
            name={"message"}
            className={styles.textarea}
            placeholder="Hello, I want to know about..."
            value={formData.message}
            onChange={handleChange}
          ></textarea>
          <button className={styles.submitButton} type="submit">
            Connect Us
          </button>
        </div>
      </form>
    );
  };

  return (
    <div className={styles.homeContainer}>
      <div className={styles.iconWrap}>
        <img
          src={images.contact_us_bg}
          className={styles.bgOurWorkStyle}
          alt={"bg_image_contact_us"}
        />
        <img
          src={images.transparent_blue}
          className={styles.transparntViewStyle}
          alt={`transparent_blue`}
        />
      </div>
      <div className={styles.headerMainContainer}>{headerComponentView}</div>
      <div className={styles.ourWorkTitleView}>
        <text className={styles.ourTxtStyle}>
          <text className={styles.workTxtStyle}> Contact </text> Us
        </text>
      </div>
      {/* Quate view */}
      <div className={styles.ourWorkContainer}>
        <img
          src={images.quote_icon}
          className={styles.quoteIconStyle}
          alt={`quote_icon`}
        />
        <text className={styles.makeEffortTxtStyle}>
          Get in touch with us today and let's discuss how we can help your
          business thrive.
        </text>
      </div>
      {/* Contact form view */}
      <div className={styles.contactFormView}>
        <ContactCard />
        <FormView />
      </div>
      {/* Ready to build your brand up with us */}
      <div className={styles.readyToBuildContainer}>
        <text className={styles.readyToBuildTxt}>
          Ready to Build your{" "}
          <text className={styles.boldText}>Brand Up with Us!</text>
        </text>
        <button className={styles.contactUsBtn}>
          <text className={styles.contactUsText}>Contact Us Today!</text>
        </button>
      </div>
      {/* Bottom Company Info View */}
      <div className={styles.bottomCompanyInfoContainer}>
        <img
          src={images.app_logo}
          className={styles.appBottomLogoImage}
          alt={"app_logo"}
        />
        <div className={styles.missionTxtContainer}>
          <text className={styles.missionTxt}>
            Our mission is to be a trusted partner for businesses, helping them
            navigate the complex world of technology and achieve their strategic
            goals. We offer a comprehensive range of IT services.
          </text>
          <div>
            <img
              src={images.insta_logo}
              className={styles.socialIcons}
              alt={"insta_logo"}
            />
            <img
              src={images.twitter_logo}
              className={styles.socialIcons}
              alt={"twitter_logo"}
            />
            <img
              src={images.fb_logo}
              className={styles.socialIcons}
              alt={"fb_logo"}
            />
          </div>
        </div>
        <div className={styles.ourServicesContainer}>
          <text className={styles.ourServicesTxt}>Our Services</text>
          <text className={styles.ourServicesDes}>
            Software Development App Development Digital Marketing Graphic
            Designing
          </text>
        </div>
        <div className={styles.callUsContainer}>
          <text className={styles.callUsTitle}>Call Us</text>
          <div className={styles.infoDetailsContainer}>
            <div className={styles.iconAndDetailView}>
              <img
                src={images.location_Icon}
                className={styles.detailIcon}
                alt={"location_icon"}
              />
              <text className={styles.companyInfoTxt}>
                Ahmedabad, Gujarat, India
              </text>
            </div>
            <div className={styles.iconAndDetailView}>
              <img
                src={images.phone_Icon}
                className={styles.detailIcon}
                alt={"phone_icon"}
              />
              <text className={styles.companyInfoTxt}>+91 85111 08143</text>
            </div>
            <div className={styles.iconAndDetailView}>
              <img
                src={images.email_Icon}
                className={styles.detailIcon}
                alt={"email_icon"}
              />
              <text className={styles.companyInfoTxt}>
                Info@bitcodingsolution.com
              </text>
            </div>
          </div>
        </div>
      </div>
      <text className={styles.reservedTxt}>
        2024, BitCoding. All right reserved
      </text>
    </div>
  );
};

export default ContactUs;
